import { styled } from '../theme.js'

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-evenly',
  marginLeft: '4rem',
  paddingTop: '3.3rem',
  paddingBottom: '3.3rem',
})

const Text = styled('h1', {
  fontFamily: '$main',
  fontWeight: '$main',
  fontSize: '$4',
  color: '$text100',
  transition: 'all 0.3s',
  textTransform: 'uppercase',
  lineHeight: '160%',
  userSelect: 'none',

  '&:hover': {
    color: '$blue',
  },
})

export function Title() {
  return (
    <Container display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
      <Text>interactive</Text>
      <Text>storytelling</Text>
    </Container>
  )
}
