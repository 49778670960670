import { styled, css } from "../theme.js";

export const Card = styled("div", {
  backgroundColor: "$bg",
  boxShadow: "1rem 1rem 40px 0px rgba(0, 0, 0, 0.25)",

  color: "$textMain",

  transition: "all 0.3s",

  "&:hover": {
    transform: "translate(-10px, -10px)",
    boxShadow: "1.8rem 1.8rem 45px 0px rgba(0, 0, 0, 0.25)",
  },
});
