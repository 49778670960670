import { styled, css } from "./theme.js";

const Wrapper = styled("div", {
  width: "100%",
  height: "auto",

  display: "flex",
  flexDirection: "column",
});

const Header = styled("div", {
  width: "100%",
  height: "5rem",

  display: "flex",
  justifyContent: "flex-end",
  webkitJustifyContent: "flex-end",
  alignItems: "center",

  variants: {
    display: {
      desktop: {
        height: "5rem",
      },
      mobile: {
        height: "8rem",
      },
    },
  },
});

const Title = styled("div", {
  width: "100%",
  height: "auto",
  marginBottom: "2rem",

  variants: {
    display: {
      desktop: {
        marginTop: "2rem",
      },
      mobile: {
        marginTop: "4rem",
      },
    },
  },
});

const Introduction = styled("div", {
  width: "100%",

  variants: {
    display: {
      desktop: {
        height: "40rem",
      },
      mobile: {
        height: "80rem",
        marginTop: "4rem",
      },
    },
  },
});

const Creds = styled("div", {
  width: "100%",

  marginTop: "3rem",

  variants: {
    display: {
      desktop: {
        height: "10rem",
      },
      mobile: {
        height: "12rem",
        marginTop: "8rem",
        marginBottom: "4rem",
      },
    },
  },
});

const ProjectIntro = styled("div", {
  width: "100%",
  height: "auto",

  marginTop: "4rem",

  variants: {
    display: {
      desktop: {
        marginBottom: "2rem",
      },
      mobile: {
        marginBottom: "0rem",
      },
    },
  },
});

const Projects = styled("div", {
  width: "100%",

  variants: {
    display: {
      desktop: {
        marginBottom: "8rem",
      },
      mobile: {
        marginBottom: "0rem",
      },
    },
  },
});

const DQSection = styled("div", {
  width: "100%",
  height: "auto",
});

export {
  Wrapper,
  Header,
  Title,
  Introduction,
  Creds,
  ProjectIntro,
  Projects,
  DQSection,
};
