import { styled, css } from "../theme.js";
import awwwardsLogo from "../assets/awwwardsLogo.svg";
import gdaLogo from "../assets/gdaLogo.svg";
import nswLogo from "../assets/nswLogo.svg";

const Container = styled("div", {
  width: "100%",
  height: "100%",

  display: "flex",

  justifyContent: "space-evenly",
  alignItems: "center",
});

export function CredsContent() {
  return (
    <Container>
      <img src={nswLogo} style={{ height: "40%" }} />

      <img src={gdaLogo} style={{ height: "40%" }} />
      <img src={awwwardsLogo} style={{ height: "20%" }} />
    </Container>
  );
}
