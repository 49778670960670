import { styled } from "../theme.js";
import dqImage from "../assets/dq-bg-square.jpg";

const Container = styled("div", {
  width: "100%",
  display: "flex",

  variants: {
    display: {
      desktop: {
        flexDirection: "row",
      },
      mobile: {
        flexDirection: "column",
      },
    },
  },
});

const TextCont = styled("div", {
  color: "$bg",
  minHeight: "20rem",
  backgroundColor: "$textMain",

  display: "flex",
  flexDirection: "column",
  justifyContent: "space-evenly",

  variants: {
    display: {
      desktop: {
        width: "50%",
      },
      mobile: {
        width: "100%",
      },
    },
  },
});

const Image = styled("img", {
  variants: {
    display: {
      desktop: {
        width: "50%",
      },
      mobile: {
        width: "100%",
      },
    },
  },
});

const Text = styled("div", {
  color: "$bg",

  lineHeight: "200%",
  zIndex: 5,

  fontFamily: "$body",

  width: "calc(100%) - 8rem",
  padding: "4rem",
  fontSize: "$2",

  variants: {
    display: {
      desktop: {
        fontSize: "$2",
      },
      mobile: {
        fontSize: "$3",
      },
    },
  },
});

const TextLink = styled("a", {
  color: "$text100",
  transition: "all 0.3s",
  userSelect: "none",

  fontFamily: "$main",
  textTransform: "uppercase",
  textDecoration: "underline",

  paddingLeft: "4rem",
  fontSize: "$3",

  "&:hover": {
    color: "$blue",
  },
});

export function DQContent() {
  return (
    <Container display={{ "@initial": "desktop", "@mobile": "mobile" }}>
      <TextCont display={{ "@initial": "desktop", "@mobile": "mobile" }}>
        <Text display={{ "@initial": "desktop", "@mobile": "mobile" }}>
          Our unique approach enables co-created digital experiences that are
          tailored to the strategic needs of our clients. By unleashing the
          power of data, we construct stories that are compelling, insightful
          and driven by evidence.{" "}
        </Text>
        <TextLink
          display={{ "@initial": "desktop", "@mobile": "mobile" }}
          href={"https://smashdeltaquest.com"}
          target={"_blank"}
        >
          Experience our process
        </TextLink>
      </TextCont>
      <Image
        src={dqImage}
        display={{ "@initial": "desktop", "@mobile": "mobile" }}
      />
    </Container>
  );
}
