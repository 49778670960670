import { styled } from '../theme.js'
import { Card } from './Card.js'

import ACFimage from '../assets/acf-image.jpg'
import SmartCitiesImage from '../assets/smart-cities-image.png'
import HEImage from '../assets/health-exp-image.png'
import GPTImage from '../assets/gpt-and-me.png'
import IPAImage from '../assets/gen-ai-and-the-ip-system.png'
import SDEImage from '../assets/sde.png'

const Container = styled('div', {
  width: '100%',

  display: 'flex',

  justifyContent: 'space-evenly',
  alignItems: 'center',

  variants: {
    display: {
      desktop: {
        flexDirection: 'row',
        flexWrap: 'wrap',
      },
      mobile: {
        flexDirection: 'column',
      },
    },
  },
})

const Project = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  marginTop: '4rem',

  variants: {
    display: {
      desktop: {
        width: '30%',
        // height: '100%',
      },
      mobile: {
        width: '90%',
        // height: '30%',
      },
    },
  },
})

const Title = styled('a', {
  fontFamily: '$body',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  textDecoration: 'none',
  color: '$textMain',
  marginBottom: '1rem',

  variants: {
    display: {
      desktop: {
        fontSize: '$2',
      },
      mobile: {
        fontSize: '$3',
      },
    },
  },
})

const Tile = styled(Card, {
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  flex: 'auto',
  aspectRatio: 1,
})

export function ProjectsContent() {
  return (
    <Container display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
      <Project display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <Title
          display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
          href={
            'https://www.ipaustralia.gov.au/temp/Generative-AI-and-the-IP-System.html'
          }
          target={'_blank'}
          rel="noreferrer"
        >
          Generative AI and the IP System
        </Title>

        <Tile
          as={'a'}
          style={{ backgroundImage: `url(${IPAImage})` }}
          href={
            'https://www.ipaustralia.gov.au/temp/Generative-AI-and-the-IP-System.html'
          }
          target={'_blank'}
          rel="noreferrer"
        />
      </Project>
      <Project display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <Title
          display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
          href={'https://gpt-and-me.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        >
          GPT and Me
        </Title>
        <Tile
          as="a"
          style={{ backgroundImage: `url(${GPTImage})` }}
          href={'https://gpt-and-me.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        />
      </Project>
      <Project display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <Title
          display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
          href={'https://sde.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        >
          Strategic Data Ecosystem
        </Title>
        <Tile
          as="a"
          style={{ backgroundImage: `url(${SDEImage})` }}
          href={'https://sde.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        />
      </Project>
      <Project display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <Title
          display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
          href={'https://themes.acf21.co.nz/'}
          target={'_blank'}
          rel="noreferrer"
        >
          Auckland Climate Festival 2021
        </Title>

        <Tile
          as={'a'}
          style={{ backgroundImage: `url(${ACFimage})` }}
          href={'https://themes.acf21.co.nz/'}
          target={'_blank'}
          rel="noreferrer"
        />
      </Project>
      <Project display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <Title
          display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
          href={'https://smartcities.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        >
          Sydney as a Smart City
        </Title>
        <Tile
          as="a"
          style={{ backgroundImage: `url(${SmartCitiesImage})` }}
          href={'https://smartcities.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        />
      </Project>
      <Project display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <Title
          display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
          href={'https://healthexplorer.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        >
          Health Explorer
        </Title>
        <Tile
          as="a"
          style={{ backgroundImage: `url(${HEImage})` }}
          href={'https://healthexplorer.smashdelta.com/'}
          target={'_blank'}
          rel="noreferrer"
        />
      </Project>
    </Container>
  )
}
