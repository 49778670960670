import * as React from "react";

function Icon(props) {
  return (
    <svg
      width={"100%"}
      height={"100%"}
      viewBox={"0 0 101 101"}
      fill={"currentColor"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M75.911.918a3.125 3.125 0 014.425 0l18.75 18.75a3.124 3.124 0 010 4.425l-62.5 62.5c-.3.298-.657.532-1.05.688l-31.25 12.5a3.12 3.12 0 01-3.37-.692 3.125 3.125 0 01-.692-3.37l12.5-31.25c.156-.394.39-.751.687-1.05l62.5-62.5zm-5.868 14.713l14.33 14.33 8.082-8.08L78.124 7.549l-8.081 8.082zm9.912 18.75L65.624 20.049 24.999 60.674v1.832h3.125a3.125 3.125 0 013.125 3.125v3.125h3.125a3.125 3.125 0 013.125 3.125v3.125h1.831L79.955 34.38zM18.949 66.724l-.663.663-9.55 23.881 23.881-9.55.663-.662a3.125 3.125 0 01-2.031-2.925v-3.125h-3.125a3.125 3.125 0 01-3.125-3.125v-3.125h-3.125a3.125 3.125 0 01-2.925-2.032z" />
    </svg>
  );
}

export default Icon;
