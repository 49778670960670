import './App.css'
import React from 'react'
import {
  Wrapper,
  Header,
  Title,
  Introduction,
  Creds,
  ProjectIntro,
  Projects,
  DQSection,
} from './containers'
import { Title as TitleContent } from './components/title'
import { IntroContent } from './components/intro'
import { CredsContent } from './components/creds'
import { ProjectIntroContent } from './components/projectIntro'
import { ProjectsContent } from './components/projects'
import { DQContent } from './components/dq'
import { Footer } from './components/Footer'
import { Enquiry } from './components/Enquiry'

function App() {
  return (
    <Wrapper>
      <Enquiry />
      <Header display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <img
          src="/SD_SS_horiztonal.svg"
          alt="Logo Smash Delta with Storyscaper"
          style={{ height: '60%', marginRight: '2rem' }}
          onClick={() => window.open('https://smashdelta.com', '_blank')}
        />
      </Header>
      <Title display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <TitleContent />
      </Title>
      <Introduction display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <IntroContent />
      </Introduction>
      <Creds display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <CredsContent />
      </Creds>
      <ProjectIntro display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <ProjectIntroContent />
      </ProjectIntro>
      <Projects display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        <ProjectsContent />
      </Projects>
      <DQSection>
        <DQContent />
      </DQSection>
      <Footer />
    </Wrapper>
  )
}

export default App
