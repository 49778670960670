import { styled } from '../theme.js'
import IntroBG from '../assets/IntroBG.jpg'
import { Card } from './Card.js'

const Container = styled('div', {
  width: '100%',
  height: '100%',

  display: 'flex',
  alignItems: 'center',

  position: 'relative',
})

const Image = styled('img', {
  height: '65%',
  width: '100%',

  objectFit: 'cover',
})

const Tile = styled(Card, {
  position: 'absolute',

  display: 'flex',
  alignItems: 'center',

  variants: {
    display: {
      desktop: {
        width: '35%',
        height: '60%',
      },
      mobile: {
        width: '70%',
        height: '45%',
      },
    },
  },
})

const Text = styled('div', {
  width: 'calc(100% - 6rem)',
  margin: '3rem',

  fontFamily: '$body',
  fontWeight: '$body',
  color: '$textMain',

  variants: {
    display: {
      desktop: {
        fontSize: '$2',
      },
      mobile: {
        fontSize: '$3',
      },
    },
  },
})

export function IntroContent() {
  return (
    <Container>
      <Image src={IntroBG} />
      <Tile
        display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
        css={{ top: 0, left: '10%' }}
      >
        <Text display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
          A more complex world requires the cutting-edge application of data,
          design and storytelling to build consensus and drive momentum.
        </Text>
      </Tile>
      <Tile
        display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
        css={{ bottom: 0, right: '10%' }}
      >
        <Text display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
          Smash Delta® Storyscaper™ is a powerful technology ecosystem - put to
          work for the delivery of impactful, interactive stories for
          Governments and major organisations.
        </Text>
      </Tile>
    </Container>
  )
}
