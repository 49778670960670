import { styled } from '../theme.js'

const Container = styled('div', {
  width: 'calc(100% - 4rem)',

  fontFamily: '$main',
  fontWeight: '$main',
  textTransform: 'uppercase',
  fontSize: '$3',
  lineHeight: '180%',

  color: '$textMain',

  variants: {
    display: {
      desktop: {
        marginLeft: '4rem',
      },
      mobile: {
        marginLeft: '2rem',
      },
    },
  },
})

// const Text = styled('p', {
//   color: '$text100',
//   transition: 'all 0.3s',
//   textTransform: 'uppercase',
//   userSelect: 'none',
//   textDecoration: 'underline',

//   '&:hover': {
//     color: '$blue',
//   },
// })

export function ProjectIntroContent() {
  return (
    <Container display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
      <span>
        We work with governments and major organisations on impactful
        initiatives.
      </span>
    </Container>
  )
}
