import { styled } from '../theme.js'
import { Card } from './Card.js'
import Icon from '../assets/pencil.js'

const Container = styled(Card, {
  width: '10rem',
  height: '10rem',

  borderRadius: '5rem',

  zIndex: 10,
  position: 'fixed',

  right: '2rem',
  bottom: '2rem',

  display: 'flex',
  flexDirection: 'column',

  justifyContent: 'center',
  alignItems: 'center',

  color: '$textMain',

  '&:hover': {
    color: '#ffffff',
    backgroundColor: '$blue',
  },

  variants: {
    display: {
      desktop: {
        width: '10rem',
        height: '10rem',

        borderRadius: '5rem',
      },
      mobile: {
        width: '15rem',
        height: '15rem',

        borderRadius: '7.5rem',
      },
    },
  },
})

const Pencil = styled('div', {
  width: '25%',
  height: '25%',
  marginTop: '0.5rem',
})

const Text = styled('span', {
  fontFamily: '$body',
  fontSize: '$1',
  userSelect: 'none',
  color: 'inherit',
  variants: {
    display: {
      desktop: {
        fontSize: '$1',
        marginTop: '1rem',
      },
      mobile: {
        fontSize: '$2a',
        marginTop: '0.5rem',
      },
    },
  },
})

export function Enquiry() {
  return (
    <Container
      display={{ '@initial': 'desktop', '@mobile': 'mobile' }}
      onClick={() => window.open('https://smashdelta.com/contact/', '_blank')}
    >
      <Pencil>
        <Icon />
      </Pencil>
      <Text display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
        Enquire
      </Text>
    </Container>
  )
}
