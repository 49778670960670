import { styled } from '../theme'
import SDLogo from '../assets/SD-logo-black.svg'

const Text = styled('div', {
  fontFamily: '$body',
  fontSize: '$1',

  maxWidth: '40rem',
})

const Container = styled('div', {
  width: 'calc(100% - 8rem)',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '$beige',
  padding: '8rem 4rem 4rem 4rem',

  // justifyContent: 'space-between',
  alignItems: 'start',

  variants: {
    display: {
      desktop: {},
      mobile: {
        [`& ${Text}`]: {
          marginBottom: '4rem',
        },
      },
    },
  },
})

const Logo = styled('img', {
  width: '8rem',
  // height: "5rem",
})

export function Footer() {
  return (
    <Container display={{ '@initial': 'desktop', '@mobile': 'mobile' }}>
      <Text>
        Storyscaper™ is a product of {' '}
        <a href="https://smashdelta.com/" target="_blank" rel="noreferrer">
          Smash Delta®
        </a>
        <br />
        <br />
        Smash Delta® is a next-generation strategic technology group that brings
        together Commercial Strategy, Science and Design. We partner with
        enterprise and government organisations to drive impact at all stages of
        the Data and Digital value creation journey.
      </Text>
      <a
        style={{ marginTop: '4rem' }}
        href="https://smashdelta.com"
        target="_blank"
        rel="noreferrer"
      >
        <Logo src={SDLogo} />
      </a>
    </Container>
  )
}
